#login{
    background-color: rgb(131, 178, 219);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

#logincontainer{
    /* border:2px solid red; */
    background-color: white;
    border-radius: 10px;
    width: 45vw;
    height: 45vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

#loginheader{
    font-size: 20px;
    font-weight: bold;
}

#loginform{
    /* border: 2px solid red; */
    width: 40vw;
    height: 35vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.fields{
    width: 40vw;
    height: 6vh;
    /* border: 2px solid black; */
    border-radius: 5px;
    
}

#lf{
    /* border: 2px solid blue; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 30vw;
}

#loginbtn{
    height: 6vh;
    width: 12vw;
    font-size: 18px;
    border-radius: 5px;
    border: none;
    background-color: black;
    color: white;
}

#fp{
    margin-left: 1vw;
}

#fp:hover{
    color: rgb(82, 10, 207);
}

#welcome{
    height: 15vh;
    background-color: white;
    box-shadow: 5px 5px 5px 5px gray;
    display: flex;
    align-items: center;
}

#clear{
    background-color: transparent;
    border: none;
    display: flex;
    width: 18vw;
    align-items: center;
    justify-content: space-between;
}

#logo{
    height: 12vh;
}

#logohead{
    font-size: 18px;
    color: black;
    font-weight: bold;
}

#dbf1{
    /* border: 2px solid red; */
    padding: 15px;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.grid-container{
    display: grid;
    grid-template-columns: auto auto;
    width: 25vw;
    padding: 0px;
    /* border: 2px solid red; */
    /* column-gap: 1vw; */
}

.grid-item {
    /* border: 2px solid red; */
    padding:15px 2px;
    width: 12vw;
    font-size: 18px;
    text-align: left;
  }

  #cctbl {
    width: 90%;
    height: 15vh;
    border-collapse: collapse;
    border: 2px solid black;
}

thead, th {
    position: sticky;
    top: 0;
    background-color: #f1f1f1; /* Optional for header styling */
    z-index: 2; /* Ensure header is above the scrolling body */
}

tbody {
    display: block;
    max-height: 15vh;
    overflow-y: auto;
}

tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* Keeps the columns aligned */
}

.td, th {
    width: 15vw;
    text-align: center;
    font-size: 15px;
    border: 2px solid black;
    border-bottom: 1px solid #733843;
    background-color: #d9b8c1;
}
