#logo {
    height: 10vh;
}

#resortName{
    font-family: "Playfair Display", serif !important;
    font-weight: bold;
}

#innav {
    /* border: 2px solid rgb(128, 0, 0); */
    width: 100vw !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important; /* Ensures space between children */
}

#navbarNav {
    /* border: 2px solid green; */
    /* Move to the right */
    margin-left: auto !important; /* Pushes the navbarNav to the right */
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important; /* Aligns contents to the right */
}

.navbar-nav {
    /* border: 2px solid rgb(43, 0, 128); */
    width: 60vw !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-around !important;
    font-weight: bold;
}

.nav-item{
    font-family: "Lato", sans-serif !important;
}
.phone{
    width: 35px;
    height: 35px;
}
.no-underline {
    text-decoration: none;
}

/* Default transparent background */
.bg-transparent {
    background-color: transparent !important;
    transition: background-color 0.3s ease-in-out;
}

/* White background when scrolled */
.bg-white {
    background-color: white !important;
    transition: background-color 0.3s ease-in-out;
}

#navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 8;
    /* border: 2px solid blue; */
}

@media only screen and (max-width: 1085px) {
    #innav {
        /* border: 2px solid rgb(41, 128, 0); */
        width: 100vw !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important; /* Ensures space between children */
    }
    
    #navbarNav {
        /* border: 2px solid green; */
        /* Move to the right */
        margin-left: auto !important; /* Pushes the navbarNav to the right */
        display: flex !important;
        align-items: flex-end !important;
        justify-content: flex-end !important; /* Aligns contents to the right */
    }
    
    .navbar-nav {
        /* border: 2px solid rgb(43, 0, 128); */
        width: 65vw !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-around !important;
        font-weight: bold;
    }
}

@media only screen and (max-width: 995px) {
    #innav {
        /* border: 2px solid rgb(128, 0, 126); */
        width: 100vw !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important; /* Ensures space between children */
    }
    
    #navbarNav {
        /* border: 2px solid green; */
        display: none !important;
        /* Move to the right */
        /* margin-left: auto !important; Pushes the navbarNav to the right */
        /* display: flex !important; */
        /* align-items: flex-end !important; */
        /* opacity: 0; */
        /* justify-content: flex-end !important; Aligns contents to the right */
    }


    #navbarNav.show {
        display: flex !important; /* Show when toggled */
        flex-direction: column; /* Stack items vertically */
        background-color: white !important;
        /* width: 100vw !important; */
        align-items: flex-start !important;
        justify-content: space-around !important;
        padding: 2%;
        font-weight: bold;
        /* transition:  0.3s ease-in-out; */
    }

    .navbar-nav {
        /* border: 2px solid rgb(43, 0, 128); */
        width: 40vw !important;
        display: flex !important;
        align-items: flex-start !important;
        justify-content: space-around !important;
        font-weight: bold;
    }
}


@media only screen and (max-width: 412px) {
    #innav {
        /* border: 2px solid rgb(0, 255, 208); */
        width: 100vw !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important; /* Ensures space between children */
    }
    #ham{
        height: 5vh;
        width: 15vw !important;
    }
    #resortName{
        font-size: 12px !important;
    }
    #logo{
        height: 8vh !important;
    }
    #navbarNav {
        display: none !important;
    }


    #navbarNav.show {
        display: flex !important; /* Show when toggled */
        flex-direction: column; /* Stack items vertically */
        background-color: white !important;
        /* width: 100vw !important; */
        align-items: flex-start !important;
        justify-content: space-around !important;
        padding: 2%;
        font-weight: bold;
        /* transition:  0.3s ease-in-out; */
    }

    .bg-transparent {
        background-color: white !important;
        transition: background-color 0.3s ease-in-out;
    }

    #resortName{
        font-family: "Playfair Display", serif !important;
        font-weight: bold;
        font-size: 14px !important;
    }

}