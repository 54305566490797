body {
    overflow-x: hidden;
}



/* Form Section Styling */
.ff {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 3% 10%;
    /* Add more padding */
    /* background-color: #f7f7f7; */
    border-radius: 10px;
    margin-bottom: 40px;
    /* Space between sections */
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
}

.ff1 {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    /* Align text to the left for headings */
}

.ff2 {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* border: 2px solid red; */
}

.contact-form {
    /* border: 2px solid red; */
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.row {
    width: 95%;
    /* border: 2px solid red; */
    display: flex;
    /* flex-wrap: nowrap; */
    justify-content: space-between;
    margin-bottom: 15px;
}

.ftxt {
    width: 45%;
    height: 6vh;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* border: 2px solid red; */
    padding-left: 10px;
    font-size: 16px;
}

#farea {
    width: 100%;
    height: 15vh;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    resize: none;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 16px;
}

/* QR Section Styling */
.qr-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    /* Add margin between sections */
}

.qr-image {
    width: 200px;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.boxheadsdesc {
    margin-bottom: 15px;
    color: #555;
}

@media only screen and (max-width: 1180px) {
    .ff2 {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        /* border: 2px solid red; */
    }
    .contact-form {
        /* border: 2px solid red; */
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .row {
        width: 95%;
        /* border: 2px solid red; */
        display: flex;
        /* flex-wrap: nowrap; */
        justify-content: space-between;
        margin-bottom: 15px;
    }
    
    .ftxt {
        width: 45%;
        height: 6vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* border: 2px solid red; */
        padding-left: 10px;
        font-size: 16px;
    }
    
    #farea {
        width: 100%;
        height: 15vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        resize: none;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 16px;
    }
    .qr-image {
        width: 18vw;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
}

@media only screen and (max-width: 1050px) {
    .ff2 {
        width: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        /* border: 2px solid rgb(72, 255, 0); */
    }
    .contact-form {
        /* border: 2px solid red; */
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .row {
        width: 95%;
        /* border: 2px solid red; */
        display: flex;
        /* flex-wrap: nowrap; */
        justify-content: space-between;
        margin-bottom: 15px;
    }
    
    .ftxt {
        width: 45%;
        height: 6vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* border: 2px solid red; */
        padding-left: 10px;
        font-size: 16px;
    }
    
    #farea {
        width: 100%;
        height: 15vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        resize: none;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 16px;
    }
    .qr-image {
        width: 18vw;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
}

@media only screen and (max-width: 885px) {
    .ff {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding: 3% 10%;
        /* Add more padding */
        /* background-color: #f7f7f7; */
        border-radius: 10px;
        margin-bottom: 40px;
        /* border: 2px solid rgb(0, 51, 255); */
        /* Space between sections */
        /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
    }
    .ff1 {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: left;
        padding: 2%;
        /* Align text to the left for headings */
    }
    .ff2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        /* border: 2px solid rgb(0, 51, 255); */
    }
    .contact-form {
        /* border: 2px solid red; */
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .row {
        width: 95%;
        /* border: 2px solid red; */
        display: flex;
        /* flex-wrap: nowrap; */
        justify-content: space-between;
        margin-bottom: 15px;
    }
    
    .ftxt {
        width: 45%;
        height: 6vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* border: 2px solid red; */
        padding-left: 10px;
        font-size: 16px;
    }
    
    #farea {
        width: 100%;
        height: 15vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        resize: none;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 16px;
    }
    .qr-image {
        width: 20vw;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
}

@media only screen and (max-width: 705px) {
    .ff {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding: 3% 10%;
        /* Add more padding */
        /* background-color: #f7f7f7; */
        border-radius: 10px;
        margin-bottom: 40px;
        /* border: 2px solid rgb(0, 51, 255); */
        /* Space between sections */
        /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
    }
    .ff1 {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: left;
        padding: 2%;
        margin-bottom: 3%;
        /* Align text to the left for headings */
    }
    .ff2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        /* border: 2px solid rgb(255, 255, 0); */
    }
    .contact-form {
        /* border: 2px solid red; */
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .row {
        width: 95%;
        /* border: 2px solid red; */
        display: flex;
        /* flex-wrap: nowrap; */
        justify-content: space-between;
        margin-bottom: 15px;
    }
    
    .ftxt {
        width: 45%;
        height: 6vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* border: 2px solid red; */
        padding-left: 10px;
        font-size: 16px;
    }
    
    #farea {
        width: 100%;
        height: 15vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        resize: none;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 16px;
    }
    .qr-image {
        width: 30vw;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
}

@media only screen and (max-width: 412px) {
    .ff {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding: 3% 10%;
        /* Add more padding */
        /* background-color: #f7f7f7; */
        border-radius: 10px;
        margin-bottom: 40px;
        /* border: 2px solid rgb(0, 51, 255); */
        /* Space between sections */
        /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
    }
    .ff1 {
        width: 90%;
        display: flex;
        flex-direction: column;
        /* align-items: flex-start; */
        justify-content: space-around;
        text-align: left;
        padding: 2%;
        /* Align text to the left for headings */
    }
    .ff2 {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        /* border: 2px solid rgb(255, 255, 0); */
    }
    .contact-form {
        /* border: 2px solid red; */
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .row {
        width: 95%;
        /* border: 2px solid red; */
        display: flex;
        flex-direction: column;
        /* flex-wrap: nowrap; */
        justify-content: space-between;
        margin-bottom: 0px;
    }
    
    .ftxt {
        width: 95%;
        margin-bottom: 5%;
        height: 6vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        /* border: 2px solid red; */
        padding-left: 10px;
        font-size: 16px;
    }
    
    #farea {
        width: 95%;
        height: 15vh;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        resize: none;
        padding-left: 10px;
        padding-top: 10px;
        font-size: 16px;
    }
    .qr-image {
        width: 45vw;
        height: auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    #submitff{
        margin-top: 5% !important;
    }
}