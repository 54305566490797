#frame1 {
    /* border: 2px solid red; */
    display: flex;
    height: 115vh;
    align-items: center;
    padding-right: 70px;
    justify-content: center;
}

#fb1 {
    /* border: 2px solid red; */
    width: 25%;
    background-color: white;
    box-shadow: -5px 0px 5px 1px gray;
    height: 55vh;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    z-index: 1;
    transform: translate(5vw, 0vh);
    padding: 15px;
    border-radius: 10px;
}

#fb2 {
    /* border: 2px solid green; */
    border-radius: 10px;
    width: 66%;
    height: 78vh;
    overflow: hidden;
    position: relative;
}

#fb2img {
    /* border: 2px solid rgb(128, 0, 0); */
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-in-out;
    /* Smooth transition for fade effect */
    opacity: 1;
    /* Default opacity */
}

.fade {
    opacity: 0;
    /* Fade out effect */
}

#head1 {
    font-family: "Lato", sans-serif !important;
    font-size: 15px;
    margin-bottom: -20px;
}

#head2 {
    font-size: 80px;
    font-family: "Noto Serif JP", serif !important;
    font-weight: bold;
    line-height: 90px;
}

#expbtn {
    font-family: "Lato", sans-serif !important;
    width: 10vw;
    height: 8vh;
    font-size: 18px;
    background-color: transparent;
    border-radius: 8px;
    transition: 0.2s ease-in-out;
}

#expbtn:hover{
    background-color: black;
    color: white;

}

@media only screen and (max-width: 1425px) {
    #frame1 {
        /* border: 2px solid rgb(0, 255, 191); */
    }

    #fb1 {
        width: 28%;
    }

    #fb2 {
        width: 70%;
    }

    #fb2img {
        /* border: 2px solid rgb(128, 0, 0); */
        width: 105%;
    }
}

@media only screen and (max-width: 1230px) {
    #frame1 {
        /* border: 2px solid rgb(208, 255, 0); */
    }

    #fb1 {
        width: 30%;
    }

    #fb2 {
        width: 70%;
    }

    #fb2img {
        width: 110%;
    }
}

@media only screen and (max-width: 1150px) {
    #frame1 {
        /* border: 2px solid rgb(187, 0, 255); */
    }

    #fb1 {
        width: 35%;
    }

    #fb2 {
        width: 75%;
    }

    #fb2img {
        width: 122%;
    }
}

@media only screen and (max-width: 1075px) {
    #frame1 {
        /* border: 2px solid rgb(255, 191, 0); */
    }

    #fb1 {
        width: 40%;
    }

    #fb2 {
        width: 78%;
    }

    #fb2img {
        width: 138%;
    }
}

@media only screen and (max-width: 1015px) {
    #frame1 {
        /* border: 2px solid rgb(0, 98, 255); */
    }

    #fb1 {
        width: 45%;
    }

    #fb2 {
        width: 80%;
    }

    #fb2img {
        width: 150%;
        transform: translate(-10vw,0vh);
    }
}

@media only screen and (max-width: 950px) {
    #frame1 {
        /* border: 2px solid rgb(0, 255, 60); */
    }

    #fb1 {
        width: 50%;
    }

    #fb2 {
        width: 85%;
    }

    #fb2img {
        width: 165%;
        transform: translate(-15vw,0vh);
    }
}

@media only screen and (max-width: 925px) {
    #frame1 {
        /* border: 2px solid rgb(255, 0, 0); */
    }

    #fb1 {
        width: 60%;
    }

    #fb2 {
        width: 90%;
    }

    #fb2img {
        width: 190%;
        transform: translate(-25vw,0vh);
    }

    #expbtn {
        width: 14vw;
        height: 8vh;
        font-size: 15px;
        background-color: transparent;
        border-radius: 8px;
    }

    #head1 {
        font-size: 14px;
        margin-bottom: -20px;
    }

    #head2 {
        font-size: 75px;
        font-weight: bold;
        line-height: 90px;
    }
}

@media only screen and (max-width: 840px) {
    #frame1 {
        /* border: 2px solid rgb(0, 255, 55); */
    }

    #fb1 {
        width: 60%;
    }

    #fb2 {
        width: 90%;
    }

    #fb2img {
        width: 210%;
    }

    #expbtn {
        width: 14vw;
        height: 8vh;
        font-size: 15px;
        background-color: transparent;
        border-radius: 8px;
    }

    #head1 {
        font-size: 14px;
        margin-bottom: -20px;
    }

    #head2 {
        font-size: 70px;
        font-weight: bold;
        line-height: 90px;
    }
}

@media only screen and (max-width: 775px) {
    #frame1 {
        /* border: 2px solid rgb(255, 140, 0); */
        height: 110vh;
        /* align-items: center; */
        padding-right: 25px;
        /* overflow-x: hidden; */
    }

    #fb1 {
        width: 50%;
        height: 50vh;
    }

    #fb2 {
        width: 90%;
    }

    #fb2img {
        width: 230%;
    }

    #expbtn {
        width: 17vw;
        height: 8vh;
        font-size: 17px;
        background-color: transparent;
        border-radius: 8px;
    }

    #head1 {
        font-size: 14px;
        margin-bottom: -20px;
    }

    #head2 {
        font-size: 50px;
        font-weight: bold;
        line-height: 70px;
    }
}

@media only screen and (max-width: 615px) {
    #frame1 {
        /* border: 2px solid rgb(43, 255, 0); */
        height: 110vh;
        /* align-items: center; */
        padding-right: 25px;
        /* overflow-x: hidden; */
    }

    #fb1 {
        width: 55%;
        height: 50vh;
    }

    #fb2 {
        width: 90%;
    }

    #fb2img {
        width: 260%;
    }

    #expbtn {
        width: 17vw;
        height: 8vh;
        font-size: 17px;
        background-color: transparent;
        border-radius: 8px;
    }

    #head1 {
        font-size: 14px;
        margin-bottom: -20px;
    }

    #head2 {
        font-size: 50px;
        font-weight: bold;
        line-height: 70px;
    }
}

@media only screen and (max-width: 580px) {
    #frame1 {
        /* border: 2px solid rgb(0, 255, 60); */
        height: 110vh;
        /* align-items: center; */
        padding-right: 25px;
        /* overflow-x: hidden; */
    }

    #fb1 {
        width: 65%;
        height: 48vh;
    }

    #fb2 {
        width: 110%;
    }

    #fb2img {
        width: 275%;
        transform: translate(-35vw,0vh);
    }

    #expbtn {
        width: 18vw;
        height: 8vh;
        font-size: 17px;
        background-color: transparent;
        border-radius: 8px;
    }

    #head1 {
        font-size: 14px;
        margin-bottom: -20px;
    }

    #head2 {
        font-size: 45px;
        font-weight: bold;
        line-height: 60px;
    }
}

@media only screen and (max-width: 540px) {
    #frame1 {
        /* border: 2px solid rgb(255, 247, 0); */
        height: 110vh;
        /* align-items: center; */
        padding-right: 25px;
        /* overflow-x: hidden; */
    }

    #fb1 {
        width: 80%;
        height: 48vh;
    }

    #fb2 {
        width: 120%;
    }

    #fb2img {
        width: 320%;
        transform: translate(-45vw,0vh);
    }

    #expbtn {
        width: 18vw;
        height: 8vh;
        font-size: 17px;
        background-color: transparent;
        border-radius: 8px;
    }

    #head1 {
        font-size: 14px;
        margin-bottom: -20px;
    }

    #head2 {
        font-size: 45px;
        font-weight: bold;
        line-height: 60px;
    }
}

@media only screen and (max-width: 412px) {
    #frame1 {
        /* border: 2px solid rgb(251, 0, 255); */
        height: 90vh;
        /* width: 100vw; */
        /* align-items: center; */
        padding-right: 25px;
        /* overflow-x: hidden; */
    }

    #fb1 {
        width: 50vw;
        height: 38vh;
    }

    #fb2 {
        width: 55vw;
        height: 60vh;
    }

    #fb2img {
        width: 420%;
        transform: translate(-80vw, 0vh);
    }

    #expbtn {
        width: 30vw;
        height: 7vh;
        font-size: 18px;
        background-color: transparent;
        border-radius: 8px;
    }

    #head1 {
        font-size: 14px;
        margin-bottom: 1px;
    }

    #head2 {
        font-size: 35px;
        font-weight: bold;
        line-height: 50px;
    }
}