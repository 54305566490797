#frame5 {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#f5b1 {
    /* border: 2px solid red; */
    width: 20vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

#f5b2 {
    /* border:2px solid red; */
    width: 50vw;
    height: 60vh;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
}

#f5b21 {
    /* height: 20vh; */
    border: none;
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    border-radius: 10px;
    padding: 2%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#f5b22 {
    /* border: 2px solid red; */
    display: flex;
    width: 34vw;
    align-items: center;
    justify-content: space-between;
}

#f5b22img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
    height: 25vh;
    width: 12vw;
}

.rrimg {
    width: 100%;
    /* Make the image take up the full width of the container */
    height: 100%;
    /* Ensure the image scales to the height of the container */
    object-fit: cover;
    /* Scale the image to fill the container, cropping if necessary */
    object-position: center;
    /* Keep the image centered */
}


@media (max-width: 1310px) {
    .boxheaddesc{
        /* border: 2px solid red; */
        /* width: 5vw; */
        font-size: 14px;
    }

    #f5b22{
        /* border: 2px solid black; */
        width: auto;
        justify-content: space-between;
    }

    #f5b22img{
        /* border: 2px solid red; */
        width: 22vw;
        height: 28vh;
       }
}

@media (max-width: 925px) {

    #f5b1{
        /* border: 2px solid red; */
        width: 15vw;
        height: auto;
    }
    .boxheaddesc{
        /* border: 2px solid red; */
        /* width: 5vw; */
        font-size: 14px;
    }

    #f5b22{
        /* border: 2px solid black; */
        width: auto;
        justify-content: space-between;
    }

    #f5b22img{
        /* border: 2px solid red; */
        width: 25vw;
        height: 22vh;
       }
}

@media (max-width: 740px) {

    #f5b1{
        /* border: 2px solid red; */
        width: 15vw;
        height: auto;
    }
    .boxheaddesc{
        /* border: 2px solid red; */
        /* width: 5vw; */
        font-size: 14px;
    }

    #f5b22{
        /* border: 2px solid black; */
        width: auto;
        justify-content: space-between;
    }

    #f5b22img{
        /* border: 2px solid red; */
        width: 25vw;
        height: 17vh;
       }
}

@media (max-width: 638px) {

    #f5b1{
        /* border: 2px solid red; */
        width: 20vw;
        height: auto;
    }
    .boxheaddesc{
        /* border: 2px solid red; */
        /* width: 5vw; */
        font-size: 14px;
    }

    #f5b22{
        /* border: 2px solid black; */
        width: auto;
        justify-content: space-between;
    }

    #f5b22img{
        /* border: 2px solid red; */
        width: 25vw;
        height: 18vh;
       }
    
       .boxheads2{
        font-size: 30px;
       }
    
       .boxheads1{
        font-size: 16px;
       }
}

@media (max-width: 610px) {

    #f5b1{
        /* border: 2px solid red; */
        width: 20vw;
        height: auto;
    }

    .boxheaddesc{
        /* border: 2px solid red; */
        /* width: 5vw; */
        font-size: 14px;
    }

    #f5b22{
        /* border: 2px solid black; */
        width: auto;
        justify-content: space-between;
    }

   #f5b22img{
    /* border: 2px solid red; */
    width: 25vw;
    height: 14vh;
   }

   .boxheads2{
    font-size: 30px;
   }

   .boxheads1{
    font-size: 16px;
   }

}

@media (max-width: 412px) {

    #frame5{
        /* border: 2px solid red; */
        flex-direction: column;
        height: 80vh;
}
    #f5b1{
        /* border: 2px solid red; */
        width: 85vw;
        height: auto;
    }
    #f5b2{
        /* border: 2px solid red; */
        width: 85vw;
        align-items: center;
        height: 45vh;
    }
    .boxheaddesc{
        /* border: 2px solid red; */
        width: 75vw;
        font-size: 14px;
    }

    #f5b22{
        /* border: 2px solid black; */
        width: auto;
        display: flex;
        justify-content: space-between;

    }

    #f5b22img{
        /* border: 2px solid red; */
        width: 25vw;
        height: 13vh;
       }
    
       .boxheads2{
        font-size: 30px;
       }
    
       .boxheads1{
        font-size: 16px;
       }

}