/* General Styles */
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    color: #333;
}

h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

/* Navbar Styles */
.landing-page .navbar .navbar-brand {
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

.landing-page .navbar ul li a {
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    font-weight: bold;
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

.landing-page .navbar.bg-white .navbar-brand {
    color: black;
    text-shadow: none;
}

.landing-page .navbar.bg-white ul li a {
    color: black;
    text-shadow: none;
}

/* Default Active Link Styles */
.landing-page .navbar ul li a.active {
    color: white; /* Ensure active link is white on a dark navbar */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Match other links */
    font-weight: bold;
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

/* Active Link When Navbar is Scrolled */
.landing-page .navbar.bg-white ul li a.active {
    color: black; /* Ensure active link is black on white navbar */
    text-shadow: none; /* Match other links in scrolled state */
}

/* Hero Section */
.hero {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                url('./Images/hero.webp') no-repeat center center/cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    position: relative;
    overflow: hidden;
}

.hero-content {
    max-width: 900px;
    padding: 20px;
}

.hero-content h1 {
    font-size: 4rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 20px;
    line-height: 1.2;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.hero-content p {
    font-size: 1.5rem;
    font-weight: 300;
    color: #f0f0f0;
    margin-bottom: 40px;
}

.cta-button {
    padding: 15px 40px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    background-color: #D2691E;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #A0522D;
    transform: scale(1.1);
}

/* About Section */
.about {
    padding: 50px;
    background: #f9f9f9;
    text-align: center;
    position: relative;
}

.about-header {
    margin-bottom: 30px;
}

.about-header h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #222;
}

.about-header p {
    font-size: 1.2rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.8;
}

.about-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

.about-card {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 300px;
    text-align: left;
}

.about-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.about-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.about-card p {
    font-size: 1rem;
    color: #666;
}

/* Gallery Section */
.gallery {
    padding: 50px;
    text-align: center;
    background: #fff;
}

.gallery h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #222;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.gallery-card {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
}

.gallery-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 12px;
    transition: transform 0.3s ease;
}

.gallery-card:hover img {
    transform: scale(1.1);
}

.gallery-card p {
    margin-top: 10px;
    font-size: 1rem;
    color: #555;
    font-weight: bold;
}

/* Packages Section */
.packages {
    padding: 50px;
    background: #f9f9f9;
    text-align: center;
}

.packages h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.package-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

/* Packages Section */
.packages {
    padding: 50px;
    background: #f9f9f9;
    text-align: center;
}

.packages h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.package-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Space between cards */
    padding: 0 20px; /* Add padding to the section */
}

.package-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    flex: 0 1 calc(33.33% - 40px); /* 3 cards per row on large screens */
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 20px;
}

.package-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.package-card img {
    width: 100%;
    height: 180px; /* Adjust image height for better aspect ratio */
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
}

.package-card h3 {
    font-size: 1.5rem;
    color: #D2691E;
    margin-bottom: 10px;
    text-align: center;
}

.package-price {
    font-size: 1.2rem;
    color: #444;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center;
}

.package-details {
    list-style-type: disc; /* Add bullets */
    padding-left: 20px; /* Indent list items */
    margin: 0;
    line-height: 1.6;
    color: #555;
}

.package-details li {
    font-size: 1rem;
    margin-bottom: 8px;
    line-height: 1.6; /* Improve readability */
}

/* Animations */
[data-aos="fade-up"] {
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.8s ease-in-out;
}

[data-aos="fade-up"].aos-animate {
    opacity: 1;
    transform: translateY(0);
}

/* Contact Section */
.contact {
    padding: 50px;
    background-color: #f4f4f4;
    text-align: center;
    animation: fadeIn 1s ease-in-out;
}

.contact h2 {
    margin-bottom: 20px;
    font-size: 2.5rem;
    color: #333;
}

.contact form {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: slideInUp 0.8s ease-in-out;
}

.form-group {
    margin-bottom: 20px;
}

.contact form input,
.contact form textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: border-color 0.3s ease;
}

.contact form input:focus,
.contact form textarea:focus {
    border-color: #D2691E;
    outline: none;
}

.contact form button {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    color: #fff;
    background-color: #D2691E;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.contact form button:hover {
    background-color: #A0522D;
    transform: scale(1.05);
}

/* Contact Info */
.contact-info {
    margin-top: 20px;
    font-size: 1rem;
    color: #555;
}

.contact-info p {
    margin: 5px 0;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}


/* Footer */
footer {
    padding: 20px;
    background-color: #111;
    color: #fff;
    text-align: center;
}

/* Responsive Styles */

@media (max-width: 1024px) {
    .package-card {
        flex: 0 1 calc(50% - 40px); /* 2 cards per row on medium screens */
    }
}

@media (max-width: 768px) {
    .landing-page .navbar .navbar-brand,
    .landing-page .navbar ul li a {
        color: black; /* Always black text for small screens */
        text-shadow: none; /* Remove shadow */
    }

    .nav-link.active {
        color: black !important; /* Force text color to black */
        text-shadow: none; /* Remove shadow */
    }

    .hero-content h1 {
        font-size: 2.5rem;
    }

    .hero-content p {
        font-size: 1rem;
    }

    .cta-button {
        padding: 10px 30px;
        font-size: 1rem;
    }

    .about-cards {
        flex-direction: column;
    }

    .about-card {
        width: 100%;
        max-width: 400px;
    }

    .gallery-card img {
        height: 150px;
    }

    .package-card {
        flex: 0 1 calc(100% - 40px); /* 1 card per row on small screens */
    }
    
}

@media (max-width: 480px) {
    .landing-page .navbar .navbar-brand,
    .landing-page .navbar ul li a {
        color: black; /* Always black text for small screens */
        text-shadow: none; /* Remove shadow */
    }

    .nav-link.active {
        color: black !important; /* Force text color to black */
        text-shadow: none; /* Remove shadow */
    }
    
    .hero-content h1 {
        font-size: 2rem;
    }

    .hero-content p {
        font-size: 0.9rem;
    }

    .cta-button {
        padding: 8px 20px;
        font-size: 0.9rem;
    }

    .gallery-card img {
        height: 120px;
    }

    .package-card img {
        height: 120px; /* Further reduce image height for extra small screens */
    }
}
