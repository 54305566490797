/* Popup Container */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: #fdf5e6; /* Light sandy beige */
    border: 2px solid #8b4513; /* Saddle brown */
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    width: 320px;
    max-width: 90%;
    text-align: center;
    padding: 25px;
    animation: slideUp 0.5s ease-in-out, fadeIn 0.5s ease-in-out;
}

/* Success and Error Specific Styling */
.popup.success {
    border-color: #4CAF50;
}

.popup.error {
    border-color: #F44336;
}

/* Popup Content Styling */
.popup-content p {
    margin: 0 0 20px;
    font-family: 'Georgia', serif;
    font-size: 18px;
    line-height: 1.6;
    color: #5a3e36; /* Dark brown */
}

.close-btn {
    background-color: #8b4513; /* Saddle brown */
    color: #fff;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close-btn:hover {
    background-color: #a0522d; /* Sienna */
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translate(-50%, 10%);
    }
    to {
        transform: translate(-50%, -50%);
    }
}

/* Responsive Media Queries */

/* For tablets and small screens */
@media only screen and (max-width: 768px) {
    .popup {
        width: 85%;
        padding: 20px;
    }

    .popup-content p {
        font-size: 16px;
    }

    .close-btn {
        font-size: 14px;
        padding: 10px 20px;
    }
}

/* For mobile screens */
@media only screen and (max-width: 480px) {
    .popup {
        width: 95%;
        padding: 15px;
    }

    .popup-content p {
        font-size: 14px;
    }

    .close-btn {
        font-size: 12px;
        padding: 8px 16px;
    }
}
