#frame3 {
    height: 100vh;
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#f3b1 {
    /* border: 2px solid red; */
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#f3b1b1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    /* border: 2px solid blue; */
    width: 20vw;
    height: 50vh;
}

#f3b1b2 {
    /* border: 2px solid blue; */
    width: 55vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#forbtnflex {
    display: flex;
    /* border: 2px so  lid green; */
    width: 10vw;
    align-items: center;
    justify-content: space-around;
}

.f3b1b2img {
    height: 50vh;
    width: 12vw;
    margin-left: 2%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    /* border: 2px solid blue; */
    transition: ease-in-out 0.2s;
}

.editimg {
    width: 100%;
    height: auto;
}

.f3b1b2img:hover {
    width: 30vw;
}

.f3b1b2img:first-child {
    width: 30vw;
    /* Default increased width for the first slide */
}

.f3b1b2img:hover~.f3b1b2img {
    width: 12vw;
    /* Decreased width for non-hovered slides */
}

.f3b1b2img:hover~.f3b1b2img:first-child {
    width: 12vw;
    /* Shrinks to this when other slides are hovered */
}

.bottomtxt {
    font-family: "Lato", sans-serif !important;
    font-size: 12px;
    position: absolute;
    color: white;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#f3b2 {
    /* border: 2px solid red; */
    width: 70vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#f3b2b1 {
    /* border: 2px solid green; */
    transform: rotate(-90deg);
    width: 65vw;
}

#f3b2b2 {
    /* border: 2px solid blue; */
    display: flex;
    height: 40vh;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
}

.f2b2sliderbtn2 {
    border-radius: 100%;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    width: 6vh; /* Making the width equal to the height for a perfect circle */
    border: 2px solid black;
    /* position: absolute; */
    /* top: 50%; */
    /* transform: translateY(-50%); */
    padding-bottom: 3%;
    line-height: 1;
    cursor: pointer;
}

.aboutbtn{
    /* width: 18vw !important; */
}

@media only screen and (max-width: 1513px) {
    #frame3 {
        height: 100vh;
        /* border: 2px solid black; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #f3b2b1 {
        /* border: 2px solid green; */
        transform: rotate(-90deg);
        width: 80vw;
        /* padding-left: -5vw !important; */
    }
}

@media only screen and (max-width: 1379px) {
    #frame3 {
        height: 105vh;
        /* border: 2px solid rgb(255, 0, 0); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #f3b2 {
        /* border: 2px solid red; */
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #f3b2b1 {
        /* border: 2px solid green; */
        transform: rotate(-90deg);
        width: 100vw;
        /* padding-left: -5vw !important; */
    }
    #f3b2b2 {
        /* border: 2px solid blue; */
        display: flex;
        height: 45vh;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1310px) {
    #frame3 {
        height: 105vh;
        /* border: 2px solid rgb(166, 0, 255); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #f3b1 {
        /* border: 2px solid red; */
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    #f3b1b1 {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        /* border: 2px solid blue; */
        width: 28vw;
        height: 50vh;
    }
    
    #f3b1b2 {
        /* border: 2px solid blue; */
        width: 60vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    #forbtnflex {
        display: flex;
        /* border: 2px so  lid green; */
        width: 10vw;
        align-items: center;
        justify-content: space-around;
    }
    
    .f3b1b2img {
        height: 50vh;
        width: 12vw;
        margin-left: 2%;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        /* border: 2px solid blue; */
        transition: ease-in-out 0.2s;
    }

    
    #f3b2 {
        /* border: 2px solid red; */
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #f3b2b1 {
        /* border: 2px solid green; */
        transform: rotate(-90deg);
        width: 100vw;
        /* padding-left: -5vw !important; */
    }
    #f3b2b2 {
        /* border: 2px solid blue; */
        display: flex;
        height: 50vh;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
    }

    
    #slide-1{
        /* border: 2px solid red; */
        width: 55vw;
    }
    #slide-3{
        /* border: 2px solid red; */
        width: 150vw;
        transform:"translate(-5vw,0vh)"
    }
}

@media only screen and (max-width: 925px) {
    #frame3 {
        height: 105vh;
        /* border: 2px solid rgb(17, 255, 0); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #f3b1 {
        /* border: 2px solid red; */
        width: 90vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    #f3b1b1 {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        /* border: 2px solid blue; */
        width: 30vw;
        height: 50vh;
    }
    
    #f3b1b2 {
        /* border: 2px solid blue; */
        width: 60vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    #forbtnflex {
        display: flex;
        /* border: 2px solid green; */
        width: 12vw;
        align-items: center;
        justify-content: space-around;
    }
    
    .f3b1b2img {
        height: 50vh;
        width: 12vw;
        margin-left: 2%;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        /* border: 2px solid blue; */
        transition: ease-in-out 0.2s;
    }

    
    #f3b2 {
        /* border: 2px solid red; */
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #f3b2b1 {
        /* border: 2px solid green; */
        transform: rotate(-90deg);
        width: 145vw;
        /* padding-left: -5vw !important; */
    }
    #f3b2b2 {
        /* border: 2px solid blue; */
        display: flex;
        height: 50vh;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
    }

    
    #slide-1{
        /* border: 2px solid red; */
        width: 55vw;
    }
    #slide-3{
        /* border: 2px solid red; */
        width: 150vw;
        transform:"translate(-5vw,0vh)"
    }
}

@media only screen and (max-width: 740px) {
    #frame3 {
        height: 145vh;
        /* border: 2px solid rgb(255, 0, 230); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #f3b1 {
        /* border: 2px solid red; */
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    #f3b1b1 {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        /* border: 2px solid blue; */
        margin-bottom: 2%;
        width: 70vw;
        height: 30vh;
    }
    
    #f3b1b2 {
        /* border: 2px solid blue; */
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    #forbtnflex {
        display: flex;
        /* border: 2px solid green; */
        width: 15vw;
        align-items: center;
        justify-content: space-around;
    }
    
    .f3b1b2img {
        height: 50vh;
        width: 20vw;
        margin-left: 2%;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        /* border: 2px solid blue; */
        transition: ease-in-out 0.2s;
    }

    .f3b1b2img:hover {
        width: 40vw;
    }
    
    .f3b1b2img:first-child {
        width: 40vw;
        /* Default increased width for the first slide */
    }
    
    .f3b1b2img:hover~.f3b1b2img {
        width: 20vw;
        /* Decreased width for non-hovered slides */
    }
    
    .f3b1b2img:hover~.f3b1b2img:first-child {
        width: 20vw;
        /* Shrinks to this when other slides are hovered */
    }

    
    #f3b2 {
        /* border: 2px solid red; */
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #f3b2b1 {
        /* border: 2px solid green; */
        transform: rotate(-90deg);
        width: 250vw;
        /* padding-left: -5vw !important; */
    }
    #f3b2b2 {
        /* border: 2px solid blue; */
        display: flex;
        height: 55vh;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
    }

    
    #slide-1{
        /* border: 2px solid red; */
        width: 55vw;
    }
    #slide-3{
        /* border: 2px solid red; */
        width: 150vw;
        transform:"translate(-5vw,0vh)"
    }
}

@media only screen and (max-width: 638px) {
    #frame3 {
        height: 150vh;
        /* border: 2px solid rgb(255, 255, 0); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #f3b1 {
        /* border: 2px solid red; */
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    #f3b1b1 {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        /* border: 2px solid blue; */
        margin-bottom: 2%;
        width: 70vw;
        height: 30vh;
    }
    
    #f3b1b2 {
        /* border: 2px solid blue; */
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    #forbtnflex {
        display: flex;
        /* border: 2px solid green; */
        width: 18vw;
        align-items: center;
        justify-content: space-around;
    }
    
    .f3b1b2img {
        height: 50vh;
        width: 20vw;
        margin-left: 2%;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        /* border: 2px solid blue; */
        transition: ease-in-out 0.2s;
    }

    .f3b1b2img:hover {
        width: 40vw;
    }
    
    .f3b1b2img:first-child {
        width: 40vw;
        /* Default increased width for the first slide */
    }
    
    .f3b1b2img:hover~.f3b1b2img {
        width: 20vw;
        /* Decreased width for non-hovered slides */
    }
    
    .f3b1b2img:hover~.f3b1b2img:first-child {
        width: 20vw;
        /* Shrinks to this when other slides are hovered */
    }

    
    #f3b2 {
        /* border: 2px solid red; */
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #f3b2b1 {
        /* border: 2px solid green; */
        transform: rotate(-90deg);
        width: 280vw;
        /* padding-left: -5vw !important; */
    }
    #f3b2b2 {
        /* border: 2px solid blue; */
        display: flex;
        height: 60vh;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
    }

    
    #slide-1{
        /* border: 2px solid red; */
        width: 55vw;
    }
    #slide-3{
        /* border: 2px solid red; */
        width: 150vw;
        transform:"translate(-5vw,0vh)"
    }

    #expbtn {
        width: 18vw;
    }
}

@media only screen and (max-width: 610px) {
    #frame3 {
        height: 150vh;
        /* border: 2px solid rgb(0, 255, 204); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #f3b1 {
        /* border: 2px solid red; */
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    #f3b1b1 {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        /* border: 2px solid blue; */
        margin-bottom: 2%;
        width: 70vw;
        height: 30vh;
    }
    
    #f3b1b2 {
        /* border: 2px solid blue; */
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    #forbtnflex {
        display: flex;
        /* border: 2px solid green; */
        width: 18vw;
        align-items: center;
        justify-content: space-around;
    }
    
    .f3b1b2img {
        height: 50vh;
        width: 20vw;
        margin-left: 2%;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        /* border: 2px solid blue; */
        transition: ease-in-out 0.2s;
    }

    .f3b1b2img:hover {
        width: 40vw;
    }
    
    .f3b1b2img:first-child {
        width: 40vw;
        /* Default increased width for the first slide */
    }
    
    .f3b1b2img:hover~.f3b1b2img {
        width: 20vw;
        /* Decreased width for non-hovered slides */
    }
    
    .f3b1b2img:hover~.f3b1b2img:first-child {
        width: 20vw;
        /* Shrinks to this when other slides are hovered */
    }

    
    #f3b2 {
        /* border: 2px solid red; */
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #f3b2b1 {
        /* border: 2px solid green; */
        transform: rotate(-90deg);
        width: 350vw;
        /* padding-left: -5vw !important; */
    }
    #f3b2b2 {
        /* border: 2px solid blue; */
        display: flex;
        height: 60vh;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
    }

    
    #slide-1{
        /* border: 2px solid red; */
        width: 55vw;
    }
    #slide-3{
        /* border: 2px solid red; */
        width: 150vw;
        transform:"translate(-5vw,0vh)"
    }

    #expbtn {
        width: 25vw;
    }
}

@media only screen and (max-width: 412px) {
    #frame3 {
        height: 160vh;
        /* border: 2px solid rgb(0, 255, 204); */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #f3b1 {
        /* border: 2px solid red; */
        width: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    
    #f3b1b1 {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
        /* border: 2px solid blue; */
        margin-bottom: 2%;
        width: 70vw;
        height: 40vh;
    }
    
    #f3b1b2 {
        /* border: 2px solid blue; */
        width: 85vw;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    #forbtnflex {
        display: flex;
        /* border: 2px solid green; */
        width: 30vw;
        align-items: center;
        justify-content: space-around;
    }
    
    .f3b1b2img {
        height: 50vh;
        width: 20vw;
        margin-left: 2%;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        /* border: 2px solid blue; */
        transition: ease-in-out 0.2s;
    }

    .f3b1b2img:hover {
        width: 40vw;
    }
    
    .f3b1b2img:first-child {
        width: 40vw;
        /* Default increased width for the first slide */
    }
    
    .f3b1b2img:hover~.f3b1b2img {
        width: 20vw;
        /* Decreased width for non-hovered slides */
    }
    
    .f3b1b2img:hover~.f3b1b2img:first-child {
        width: 20vw;
        /* Shrinks to this when other slides are hovered */
    }

    
    #f3b2 {
        /* border: 2px solid red; */
        width: 85vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    #f3b2b1 {
        /* border: 2px solid green; */
        transform: rotate(0deg);
        width: 50vw;
        margin-bottom: 2%;
        /* padding-left: -5vw !important; */
    }
    #f3b2b2 {
        /* border: 2px solid blue; */
        display: flex;
        height: 55vh;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: column;
    }

    
    #slide-1{
        /* border: 2px solid red; */
        width: 55vw;
    }
    #slide-3{
        /* border: 2px solid red; */
        width: 150vw;
        transform:"translate(-5vw,0vh)"
    }

    #expbtn {
        width: 30vw;
    }

    .aboutbtn{
        width: 35vw !important;
    }
}