#frame2 {
    /* border: 2px solid green; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#f2b1 {
    /* border: 2px solid hotpink; */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#f2b1b1 {
    display: flex;
    width: 100%;
    /* border: 2px solid red; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.boxheads1 {
     font-family: "Lato", sans-serif !important;
    font-size: 22px;
}

.boxheads2 {
    font-family: "Noto Serif JP", serif !important;
    font-size: 50px;
}

.boxheadsdesc {
    font-size: 19px;
    width: 90%;
    /* border: 2px solid red; */
}

#f2b2 {
    width: 70%;
    height: 70%;
    /* border: 2px solid hotpink; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    /* Make f2b2 the reference point for absolute positioning */
}

#f2b2slider {
    /* border: 2px solid hotpink; */
    height: 90%;
    width: 80%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

#f2b2slider img {
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    /* This ensures the image fits well without distortion */
    transition: transform 0.3s ease-in-out;
}

/* Slide 1 specific styling */
#f2b2slider .slide1 {
    filter: brightness(0.9);
    height: 117%;
    /* Height changes instantly */
}

/* Slide 2 specific styling */
#f2b2slider .slide2 {
    filter: sepia(0.6);
    height: 150%;
    /* Height changes instantly */
    /* transform: translate(0vw, -10vh); Translate instantly */
}

/* Zoom effect on hover */
#f2b2slider img:hover {
    transform: scale(1.1);
    /* Only zoom transitions smoothly */
}


.f2b2sliderbtn {
    border-radius: 100%;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    width: 6vh;
    /* Making the width equal to the height for a perfect circle */
    border: 2px solid black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-bottom: 0.4%;
    line-height: 1;
    /* Ensures text height aligns with flex centering */
}


#f2b2sliderbtnprev {
    left: 10px;
    /* Position the previous button on the left side */
}

#f2b2sliderbtnnext {
    right: 10px;
    /* Position the next button on the right side */
}

.f2b2sliderbtn:hover {
    cursor: pointer;
}


@media only screen and (max-width: 1285px) {
    #frame2 {
        /* border: 2px solid rgb(128, 0, 87); */
        height: 100vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 85%;
    }
}

@media only screen and (max-width: 1165px) {
    #frame2 {
        /* border: 2px solid rgb(0, 64, 255); */
        height: 100vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 90%;
    }

    #f2b2 {
        width: 80%;
        height: 70%;
    }

    #f2b2slider {
        height: 90%;
        width: 85%;
    }
}

@media only screen and (max-width: 1085px) {
    #frame2 {
        /* border: 2px solid rgb(4, 255, 0); */
        height: 100vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 95%;
    }

    #f2b2 {
        width: 80%;
        height: 70%;
    }

    #f2b2slider {
        height: 90%;
        width: 85%;
    }
}

@media only screen and (max-width: 1025px) {
    #frame2 {
        /* border: 2px solid rgb(242, 255, 0); */
        height: 100vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 95%;
    }

    .boxheads1 {
        font-size: 20px;
    }

    .boxheads2 {
        font-size: 45px;
    }

    .boxheadsdesc {
        font-size: 18px;
        width: 90%;
        /* border: 2px solid red; */
    }

    #f2b2 {
        width: 85%;
        height: 70%;
    }

    #f2b2slider {
        /* border: 2px solid hotpink; */
        height: 90%;
        width: 85%;
    }

    .f2b2sliderbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0.5%;
        line-height: 1;
        /* Ensures text height aligns with flex centering */
    }

}

@media only screen and (max-width: 925px) {
    #frame2 {
        /* border: 2px solid rgb(0, 255, 26); */
        height: 95vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 95%;
    }


    #f2b1b1 {
        display: flex;
        width: 100%;
        /* border: 2px solid red; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .boxheadsdesc {
        width: 90%;
        /* border: 2px solid red; */
    }

    .boxheads1 {
        font-size: 20px;
    }

    .boxheads2 {
        font-size: 40px;
    }

    .boxheadsdesc {
        font-size: 17px;
        width: 90%;
        /* border: 2px solid red; */
    }

    #f2b2 {
        /* border: 2px solid hotpink; */
        width: 85%;
        height: 70%;
    }

    #f2b2slider {
        /* border: 2px solid hotpink; */
        height: 90%;
        width: 80%;
    }

    .f2b2sliderbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0.5%;
        line-height: 1;
        /* Ensures text height aligns with flex centering */
    }

}

@media only screen and (max-width: 835px) {
    #frame2 {
        /* border: 2px solid rgb(0, 221, 255); */
        height: 80vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 90%;
        /* align-items: center; */
    }


    #f2b1b1 {
        display: flex;
        width: 50%;
        /* border: 2px solid red; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .boxheadsdesc {
        width: 90%;
        /* border: 2px solid red; */
    }

    .boxheads1 {
        font-size: 20px;
    }

    .boxheads2 {
        font-size: 40px;
    }

    .boxheadsdesc {
        font-size: 17px;
        width: 90%;
        /* border: 2px solid red; */
    }

    #f2b2 {
        /* border: 2px solid hotpink; */
        width: 85%;
        height: 60%;
    }

    #f2b2slider {
        /* border: 2px solid hotpink; */
        height: 80%;
        width: 80%;
    }

    .f2b2sliderbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0.5%;
        line-height: 1;
        /* Ensures text height aligns with flex centering */
    }


    #f2b2slider img {
        height: auto;
        max-height: 125%;
        width: auto;
        max-width: 125%;
    }   

}

@media only screen and (max-width: 760px) {
    #frame2 {
        /* border: 2px solid rgb(255, 0, 0); */
        height: 80vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 90%;
        /* align-items: center; */
    }


    #f2b1b1 {
        display: flex;
        width: 60%;
        /* border: 2px solid red; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .boxheadsdesc {
        width: 90%;
        /* border: 2px solid red; */
    }

    .boxheads1 {
        font-size: 20px;
    }

    .boxheads2 {
        font-size: 40px;
    }

    .boxheadsdesc {
        font-size: 17px;
        width: 90%;
        /* border: 2px solid red; */
    }

    #f2b2 {
        /* border: 2px solid hotpink; */
        width: 85%;
        height: 60%;
    }

    #f2b2slider {
        /* border: 2px solid hotpink; */
        height: 80%;
        width: 80%;
    }

    .f2b2sliderbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0.6%;
        line-height: 1;
        /* Ensures text height aligns with flex centering */
    }


    #f2b2slider img {
        height: auto;
        max-height: 125%;
        width: auto;
        max-width: 125%;
    }   

}

@media only screen and (max-width: 675px) {
    #frame2 {
        /* border: 2px solid rgb(0, 255, 17); */
        height: 80vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 90%;
        /* align-items: center; */
    }


    #f2b1b1 {
        display: flex;
        width: 70%;
        /* border: 2px solid red; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .boxheadsdesc {
        width: 90%;
        /* border: 2px solid red; */
    }

    .boxheads1 {
        font-size: 20px;
    }

    .boxheads2 {
        font-size: 40px;
    }

    .boxheadsdesc {
        font-size: 17px;
        width: 90%;
        /* border: 2px solid red; */
    }

    #f2b2 {
        /* border: 2px solid hotpink; */
        width: 85%;
        height: 55%;
    }

    #f2b2slider {
        /* border: 2px solid hotpink; */
        height: 80%;
        width: 75%;
    }

    .f2b2sliderbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0.6%;
        line-height: 1;
        /* Ensures text height aligns with flex centering */
    }


    #f2b2slider img {
        height: auto;
        max-height: 125%;
        width: auto;
        max-width: 125%;
    }   

}

@media only screen and (max-width: 620px) {
    #frame2 {
        /* border: 2px solid rgb(255, 247, 0); */
        height: 80vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 90%;
        /* align-items: center; */
    }


    #f2b1b1 {
        display: flex;
        width: 70%;
        /* border: 2px solid red; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .boxheadsdesc {
        width: 90%;
        /* border: 2px solid red; */
    }

    .boxheads1 {
        font-size: 20px;
    }

    .boxheads2 {
        font-size: 35px;
    }

    .boxheadsdesc {
        font-size: 17px;
        width: 90%;
        /* border: 2px solid red; */
    }

    #f2b2 {
        /* border: 2px solid hotpink; */
        width: 85%;
        height: 55%;
    }

    #f2b2slider {
        /* border: 2px solid hotpink; */
        height: 80%;
        width: 75%;
    }

    .f2b2sliderbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0.6%;
        line-height: 1;
        /* Ensures text height aligns with flex centering */
    }


    #f2b2slider img {
        height: auto;
        max-height: 125%;
        width: auto;
        max-width: 125%;
    }   

}

@media only screen and (max-width: 560px) {
    #frame2 {
        /* border: 2px solid rgb(187, 0, 255); */
        height: 80vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 90%;
        /* align-items: center; */
    }


    #f2b1b1 {
        display: flex;
        width: 85%;
        /* border: 2px solid red; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .boxheadsdesc {
        width: 90%;
        /* border: 2px solid red; */
    }

    .boxheads1 {
        font-size: 19px;
    }

    .boxheads2 {
        font-size: 35px;
    }

    .boxheadsdesc {
        font-size: 15px;
        width: 90%;
        /* border: 2px solid red; */
    }

    #f2b2 {
        /* border: 2px solid hotpink; */
        width: 85%;
        height: 55%;
    }

    #f2b2slider {
        /* border: 2px solid hotpink; */
        height: 80%;
        width: 70%;
    }

    .f2b2sliderbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0.9%;
        line-height: 1;
        /* Ensures text height aligns with flex centering */
    }


    #f2b2slider img {
        height: auto;
        max-height: 130%;
        width: auto;
        max-width: 125%;
    }   

}


@media only screen and (max-width: 412px) {
    #frame2 {
        /* border: 2px solid rgb(187, 0, 255); */
        height: 80vh;
    }

    #f2b1 {
        /* border: 2px solid hotpink; */
        width: 90%;
        flex-direction: column;
    }


    #f2b1b1 {
        display: flex;
        width: 90%;
        /* border: 2px solid red; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }

    .boxheadsdesc {
        width: 90%;
        /* border: 2px solid red; */
    }

    .boxheads1 {
        font-size: 19px;
    }

    .boxheads2 {
        font-size: 35px;
    }

    .boxheadsdesc {
        font-size: 15px;
        width: 90%;
        /* border: 2px solid red; */
    }

    #f2b2 {
        /* border: 2px solid hotpink; */
        width: 90%;
        height: 40%;
    }

    #f2b2slider {
        /* border: 2px solid hotpink; */
        height: 100%;
        width: 65%;
    }

    .f2b2sliderbtn {
        border-radius: 100%;
        font-size: 22px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5vh;
        width: 5vh;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0.9%;
        line-height: 1;
        /* Ensures text height aligns with flex centering */
    }


    #f2b2slider img {
        height: auto;
        max-height: 200%;
        width: auto;
        max-width: 220%;
    }   

}