#ggframe2 {
display: flex;
align-items: center;
justify-content: center;
    padding: 2%;
    /* border: 2px solid red; */
}
#ggframe {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 2vw; /* Minimize the gap between the cards */
    justify-content: center; /* Center the grid */
    padding: 2%;
    /* border: 2px solid red; */
}

.card {
    /* border: 2px solid red; */
    width: 25vw; /* Fixed width */
    height: auto; /* Fixed height */
    display: flex;
    border: none;
    justify-content: center; /* Horizontally center the image */
    align-items: center; /* Vertically center the image */
    overflow: hidden; /* Prevent image overflow */
    border-radius: 5px; /* Slight border radius */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  */
    background-color: transparent;
    transition: transform 0.3s ease;
    position: relative;
}

.card-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the image covers the entire card */
}


.card:hover {
    transform: scale(1.02); /* Slight scale-up on hover */
}


@media only screen and (max-width: 740px) {
    .card {
        width: 28vw; 
    }
}

@media only screen and (max-width: 412px) {
    #ggframe {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .card {
        width: 40vw; 
    }
}
