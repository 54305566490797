#abframe1 {
    /* border: 2px   solid red; */
    height: 100vh;
    position: relative;
    /* display: flex; */
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */
    overflow: hidden;

}


#forimg {
    height: 70vh;
    /* width: 100vw; */
    /* border: 2px solid rgb(0, 251, 255); */
    background-size: 100% 100vh;
    background-position: 0vw -20vh;
    background-repeat: no-repeat;
}

.modalcontainer2 {
    /* border: 2px solid rgb(43, 255, 0); */
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 95vh;
    /* width: 100vw; */
    /* opacity: ; */
    pointer-events: none;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 5;
    /* transition: opacity 0.3s ease; */
}

.modal2 {
    background-color: transparent;
    background-image: linear-gradient(120deg, rgb(255, 255, 255, 0.3), rgb(0, 0, 0, 0.2));
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(10px);
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    width: 55vw;
    height: 55vh;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgb(0, 0, 0, 0.3); */
    text-align: center;
}

#abframe2 {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* border: 2px solid red; */
}

#abf2b1 {
    /* border: 2px solid red; */
    width: 30vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

#abf2img {
    /* border: 2px solid blue; */
    height: 30vh;
    width: 30vw;
    overflow: hidden;
    border-radius: 10px;
}

#slideabf {
    width: 30vw;
    transform: translate(0vw, -13vh);
}

#slideabf2 {
    width: 30vw;
    transform: translate(0vw, 0vh);
}

#abf2b2 {
    /* border: 2px solid red; */
    width: 50vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}


@media only screen and (max-width: 1268px) {
    #abframe1 {
        /* border: 2px solid red; */
    }

    #forimg {
        height: 70vh;
        /* width: 100vw; */
        /* border: 2px solid rgb(0, 251, 255); */
        background-size: 100% 100vh;
        background-position: 0vw -20vh;
        background-repeat: no-repeat;
    }

    .modal2 {
        width: 60vw;
        height: 60vh;
    }
}

@media only screen and (max-width: 1380px) {
    #abframe2 {
        /* border: 2px solid red; */
    }
    #abf2b1 {
        /* border: 2px solid red; */
        width: 35vw;
        height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    #abf2img {
        /* border: 2px solid blue; */
        height: 30vh;
        width: 30vw;
        overflow: hidden;
        border-radius: 10px;
    }
    #slideabf {
        width: 35vw;
        transform: translate(-2vw, -13vh);
    }
    
    #slideabf2 {
        width: 30vw;
        transform: translate(0vw, -10vh);
    }
}

@media only screen and (max-width: 1180px) {
    #abframe2 {
        /* border: 2px solid rgb(9, 255, 0); */
    }
    #abf2b1 {
        /* border: 2px solid red; */
        width: 40vw;
        height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    #abf2img {
        /* border: 2px solid blue; */
        height: 30vh;
        width: 40vw;
        overflow: hidden;
        border-radius: 10px;
    }
    #slideabf {
        width: 45vw;
        transform: translate(-5vw, -13vh);
    }
    
    #slideabf2 {
        width: 45vw;
        transform: translate(0vw, -10vh);
    }
}

@media only screen and (max-width: 920px) {
    #abframe2 {
        /* border: 2px solid rgb(255, 0, 247); */
    }
    #abf2b1 {
        /* border: 2px solid red; */
        width: 40vw;
        height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    #abf2img {
        /* border: 2px solid blue; */
        height: 30vh;
        width: 40vw;
        overflow: hidden;
        border-radius: 10px;
    }
    #slideabf {
        width: 50vw;
        transform: translate(-5vw, -13vh);
    }
    
    #slideabf2 {
        width: 50vw;
        transform: translate(0vw, -10vh);
    }
}

@media only screen and (max-width: 820px) {
    #abframe2 {
        /* border: 2px solid rgb(251, 255, 0); */
    }
    #abf2b1 {
        /* border: 2px solid red; */
        width: 40vw;
        height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    #abf2img {
        /* border: 2px solid blue; */
        height: 30vh;
        width: 40vw;
        overflow: hidden;
        border-radius: 10px;
    }
    #slideabf {
        width: 55vw;
        transform: translate(-5vw, -13vh);
    }
    
    #slideabf2 {
        width: 55vw;
        transform: translate(0vw, -10vh);
    }
}

@media only screen and (max-width: 755px) {
    #abframe2 {
        /* border: 2px solid rgb(162, 0, 255); */
    }
    #abf2b1 {
        /* border: 2px solid red; */
        width: 40vw;
        height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    #abf2img {
        /* border: 2px solid blue; */
        height: 30vh;
        width: 40vw;
        overflow: hidden;
        border-radius: 10px;
    }
    #slideabf {
        width: 60vw;
        transform: translate(-8vw, -13vh);
    }
    
    #slideabf2 {
        width: 60vw;
        transform: translate(0vw, -10vh);
    }

    #abf2b2 {
        /* border: 2px solid red; */
        width: 50vw;
        height: 65vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 690px) {
    #abframe2 {
        /* border: 2px solid rgb(102, 255, 0); */
        flex-direction: column;
        height: auto;
    }
    #abf2b1 {
        /* border: 2px solid red; */
        width: 85vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    #abf2img {
        /* border: 2px solid blue; */
        height: 35vh;
        width: 85vw;
        overflow: hidden;
        border-radius: 10px;
    }
    #slideabf {
        width: 100vw;
        transform: translate(-8vw, -22vh);
    }
    
    #slideabf2 {
        width: 110vw;
        transform: translate(0vw, -10vh);
    }

    #abf2b2 {
        /* border: 2px solid red; */
        width: 85vw;
        height: 55vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 580px) {
    #abframe2 {
        /* border: 2px solid rgb(255, 196, 0); */
        flex-direction: column;
        height: auto;
    }
    #abf2b1 {
        /* border: 2px solid red; */
        width: 85vw;
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    #abf2img {
        /* border: 2px solid blue; */
        height: 35vh;
        width: 85vw;
        overflow: hidden;
        border-radius: 10px;
    }
    #slideabf {
        width: 100vw;
        transform: translate(-8vw, -15vh);
    }
    
    #slideabf2 {
        width: 110vw;
        transform: translate(0vw, -10vh);
    }

    #abf2b2 {
        /* border: 2px solid red; */
        width: 85vw;
        height: 55vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 1050px) {
    #abframe1 {
        /* border: 2px solid rgb(0, 21, 255); */
    }

    #forimg {
        height: 70vh;
        /* width: 100vw; */
        /* border: 2px solid rgb(0, 251, 255); */
        background-size: 120% 100vh;
        background-position: -5vw -20vh;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 870px) {
    #abframe1 {
        /* border: 2px solid rgb(208, 255, 0); */
    }

    #forimg {
        height: 70vh;
        /* width: 100vw; */
        /* border: 2px solid rgb(0, 251, 255); */
        background-size: 140% 100vh;
        background-position: -15vw -20vh;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 750px) {
    #abframe1 {
        /* border: 2px solid rgb(255, 0, 195); */
    }

    #forimg {
        height: 70vh;
        /* width: 100vw; */
        /* border: 2px solid rgb(0, 251, 255); */
        background-size: 160% 100vh;
        background-position: -18vw -15vh;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 680px) {
    #abframe1 {
        /* border: 2px solid rgb(0, 255, 255); */
    }

    #forimg {
        height: 70vh;
        /* width: 100vw; */
        /* border: 2px solid rgb(0, 251, 255); */
        background-size: 180% 100vh;
        background-position: -25vw -15vh;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 590px) {
    #abframe1 {
        /* border: 2px solid rgb(255, 0, 0); */
    }

    #forimg {
        height: 70vh;
        /* width: 100vw; */
        /* border: 2px solid rgb(0, 251, 255); */
        background-size: 195% 100vh;
        background-position: -35vw -15vh;
        background-repeat: no-repeat;
    }
}

@media only screen and (max-width: 412px) {
    #abframe1 {
        /* border: 2px solid rgb(247, 0, 255); */
        height: 90vh;
    }

    #forimg {
        height: 70vh;
        /* width: 100vw; */
        /* border: 2px solid rgb(0, 251, 255); */
        background-size: 270% 100vh;
        background-position: -45vw -15vh;
        background-repeat: no-repeat;
    }

    .modalcontainer2 {
        /* border: 2px solid rgb(43, 255, 0); */
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 85vh;
        /* width: 100vw; */
        /* opacity: ; */
        pointer-events: none;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-wrap: wrap;
        z-index: 5;
        /* transition: opacity 0.3s ease; */
    }

    .modal2 {
        width: 85vw;
        height: auto;
    }

    #abframe2 {
        /* border: 2px solid rgb(0, 255, 229); */
        flex-direction: column;
        height: 120vh;
    }
    #abf2b1 {
        /* border: 2px solid red; */
        width: 90vw;
        height: 55vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    #abf2img {
        /* border: 2px solid blue; */
        height: 35vh;
        width: 90vw;
        overflow: hidden;
        border-radius: 10px;
    }
    #slideabf {
        width: 150vw;
        transform: translate(-20vw, -15vh);
    }
    
    #slideabf2 {
        width: 110vw;
        transform: translate(0vw, -10vh);
    }

    #abf2b2 {
        /* border: 2px solid red; */
        width: 90vw;
        height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
}