#ppframe {
    /* border:2px solid red; */
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.cardbox {
    border: none;
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    border-radius: 10px;
    padding: 2%;
    margin: 2%;
    width: 65vw;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.ppcardhead {
    font-weight: bold;
}


.cardboxp2 {
    /* border: 2px solid blue; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.cardimg {
    /* border: 2px solid yellow; */
    width: 40vw;
    height: 35vh;
    border-radius: 10px;
    margin: 2%;
}

.cardrest {
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 2%;

}

.carddesc {
    margin: 2%;
}

.cardlists {
    margin: 2%;
}

.bookbtn {
    margin: 2% !important;
    border: 2px solid black !important;
    background-color: white !important;
    color: black !important;
    width: 11vw !important;
    height: 8vh;
    font-size: 18px;
    border-radius: 10px;
    transition: ease-in-out 0.2s;
}

.bookbtn:hover {
    background-color: black !important;
    color: white !important;
}

/* Style for the booking form popup */
.booking-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.booking-form-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* max-width: 500px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 65vw;
    height: 65vh;
    text-align: center;
    animation: slideIn 0.3s ease;
}

.booking-form-card h2 {
    /* margin-bottom: 20px; */
    font-size: 24px;
    font-weight: bold;
}

#formflex {
    /* border: 2px solid red; */
    width: 60vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.rowfff {
    /* border: 2px solid blue; */
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 60vw;
    margin: 1%;
}

.booking-form-card input,
.booking-form-card textarea {
    width: 45%;
    padding: 10px;
    /* margin: 10px 0; */
    border-radius: 5px;
    border: 1px solid #ccc;
}

.booking-form-card textarea {
    resize: none;
    height: 80px;
}

#tt {
    width: 100% !important;
}

.submit-booking-btn {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 12vw !important;
    /* margin-top: 10px; */
}

.close-form-btn {
    /* margin-top: 15px; */
    padding: 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    width: 12vw !important;
    border-radius: 5px;
    cursor: pointer;
}

.submit-booking-btn:hover,
.close-form-btn:hover {
    opacity: 0.8;
}

/* Animation */
@keyframes slideIn {
    from {
        transform: translateY(-100px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media only screen and (max-width: 1090px) {
    #ppframe {
        /* border: 2px solid red; */
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardbox {
        border: none;
        box-shadow: 5px 5px 5px 5px gray;
        background-color: white;
        border-radius: 10px;
        padding: 2%;
        margin: 2%;
        width: 75vw;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .ppcardhead {
        font-weight: bold;
    }


    .cardboxp2 {
        /* border: 2px solid blue; */
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .cardimg {
        /* border: 2px solid yellow; */
        width: 40vw;
        height: 35vh;
        border-radius: 10px;
        margin: 2%;
    }

    .cardrest {
        /* border: 2px solid yellow; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 2%;

    }

    .carddesc {
        margin: 2%;
    }

    .cardlists {
        margin: 2%;
    }

    .bookbtn {
        margin: 2% !important;
        border: 2px solid black !important;
        background-color: white !important;
        color: black !important;
        width: 11vw !important;
        height: 8vh;
        font-size: 18px;
        border-radius: 10px;
        transition: ease-in-out 0.2s;
    }
}

@media only screen and (max-width: 950px) {
    #ppframe {
        /* border: 2px solid rgb(0, 255, 4); */
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardbox {
        border: none;
        box-shadow: 5px 5px 5px 5px gray;
        background-color: white;
        border-radius: 10px;
        padding: 2%;
        margin: 2%;
        width: 80vw;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .ppcardhead {
        font-weight: bold;
    }


    .cardboxp2 {
        /* border: 2px solid blue; */
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .cardimg {
        /* border: 2px solid yellow; */
        width: 40vw;
        height: 35vh;
        border-radius: 10px;
        margin: 2%;
    }

    .cardrest {
        /* border: 2px solid yellow; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 2%;

    }

    .carddesc {
        margin: 2%;
    }

    .cardlists {
        margin: 2%;
    }

    .bookbtn {
        margin: 2% !important;
        border: 2px solid black !important;
        background-color: white !important;
        color: black !important;
        width: 13vw !important;
        height: 8vh;
        font-size: 18px;
        border-radius: 10px;
        transition: ease-in-out 0.2s;
    }
}

@media only screen and (max-width: 800px) {
    #ppframe {
        /* border: 2px solid rgb(255, 157, 0); */
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardbox {
        border: none;
        box-shadow: 5px 5px 5px 5px gray;
        background-color: white;
        border-radius: 10px;
        padding: 2%;
        margin: 2%;
        width: 80vw;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .ppcardhead {
        font-weight: bold;
    }


    .cardboxp2 {
        /* border: 2px solid blue; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardimg {
        /* border: 2px solid yellow; */
        width: 40vw;
        height: 35vh;
        border-radius: 10px;
        margin: 2%;
    }

    .cardrest {
        /* border: 2px solid yellow; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 2%;

    }

    .carddesc {
        margin: 2%;
    }

    .cardlists {
        margin: 2%;
    }

    .bookbtn {
        margin: 2% !important;
        border: 2px solid black !important;
        background-color: white !important;
        color: black !important;
        width: 15vw !important;
        height: 8vh;
        font-size: 18px;
        border-radius: 10px;
        transition: ease-in-out 0.2s;
    }

    .booking-form-card {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        /* max-width: 500px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 65vw;
        height: 65vh;
        text-align: center;
        animation: slideIn 0.3s ease;
    }

    .rowfff {
        /* border: 2px solid blue; */
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 60vw;
        margin: 2%;
    }
}

@media only screen and (max-width: 740px) {
    #ppframe {
        /* border: 2px solid rgb(255, 0, 0); */
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardbox {
        border: none;
        box-shadow: 5px 5px 5px 5px gray;
        background-color: white;
        border-radius: 10px;
        padding: 2%;
        margin: 2%;
        width: 80vw;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .ppcardhead {
        font-weight: bold;
    }


    .cardboxp2 {
        /* border: 2px solid blue; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardimg {
        /* border: 2px solid yellow; */
        width: 40vw;
        height: 35vh;
        border-radius: 10px;
        margin: 2%;
    }

    .cardrest {
        /* border: 2px solid yellow; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 2%;

    }

    .carddesc {
        margin: 2%;
    }

    .cardlists {
        margin: 2%;
    }

    .bookbtn {
        margin: 2% !important;
        border: 2px solid black !important;
        background-color: white !important;
        color: black !important;
        width: 18vw !important;
        height: 8vh;
        font-size: 18px;
        border-radius: 10px;
        transition: ease-in-out 0.2s;
    }

    .booking-form-card {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        /* max-width: 500px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 70vw;
        height: 70vh;
        text-align: center;
        animation: slideIn 0.3s ease;
    }

    .rowfff {
        /* border: 2px solid blue; */
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 65vw;
        margin: 2%;
    }

    .booking-form-card input,
    .booking-form-card textarea {
        width: 50%;
        padding: 10px;
        margin: 0% 2%;
        border-radius: 5px;
        border: 1px solid #ccc;
    }
}
@media only screen and (max-width: 635px) {
    #ppframe {
        /* border: 2px solid rgb(0, 255, 17); */
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardbox {
        border: none;
        box-shadow: 5px 5px 5px 5px gray;
        background-color: white;
        border-radius: 10px;
        padding: 2%;
        margin: 2%;
        width: 80vw;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .ppcardhead {
        font-weight: bold;
    }


    .cardboxp2 {
        /* border: 2px solid blue; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardimg {
        /* border: 2px solid yellow; */
        width: 55vw;
        height: 35vh;
        border-radius: 10px;
        margin: 2%;
    }

    .cardrest {
        /* border: 2px solid yellow; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 2%;

    }

    .carddesc {
        margin: 2%;
    }

    .cardlists {
        margin: 2%;
    }

    .bookbtn {
        margin: 2% !important;
        border: 2px solid black !important;
        background-color: white !important;
        color: black !important;
        width: 25vw !important;
        height: 7vh;
        font-size: 18px;
        border-radius: 10px;
        transition: ease-in-out 0.2s;
    }

    .booking-form-card {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        /* max-width: 500px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 70vw;
        height: 90vh;
        text-align: center;
        animation: slideIn 0.3s ease;
    }

    .rowfff {
        /* border: 2px solid blue; */
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        width: 65vw;
        margin: 0%;
    }
    
    #newfff{
        flex-direction: row !important;
        justify-content: space-evenly;
        margin: 2%;
    }

    .booking-form-card input,
    .booking-form-card textarea {
        width: 95%;
        padding: 10px;
        margin: 2% 2%;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    #tt {
        width: 95% !important;
    }

    .submit-booking-btn {
        background-color: #007bff;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 14vw !important;
        /* margin-top: 10px; */
    }
    
    .close-form-btn {
        /* margin-top: 15px; */
        padding: 10px;
        background-color: #ff4d4d;
        color: white;
        border: none;
        width: 14vw !important;
        border-radius: 5px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 412px) {
    #ppframe {
        /* border: 2px solid rgb(0, 255, 17); */
        padding: 2%;
        /* height: 220vh; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardbox {
        border: none;
        box-shadow: 5px 5px 5px 5px gray;
        background-color: white;
        border-radius: 10px;
        padding: 2%;
        margin: 2%;
        width: 85vw;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }

    .ppcardhead {
        font-weight: bold;
    }


    .cardboxp2 {
        /* border: 2px solid blue; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .cardimg {
        /* border: 2px solid yellow; */
        width: 60vw;
        height: 35vh;
        border-radius: 10px;
        margin: 2%;
    }

    .cardrest {
        /* border: 2px solid yellow; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding: 2%;

    }

    .carddesc {
        margin: 2%;
    }

    .cardlists {
        margin: 2%;
    }

    .bookbtn {
        margin: 2% !important;
        border: 2px solid black !important;
        background-color: white !important;
        color: black !important;
        width: 28vw !important;
        height: 7vh;
        font-size: 18px;
        border-radius: 10px;
        transition: ease-in-out 0.2s;
    }

    .booking-form-card {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        /* max-width: 500px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 85vw;
        height: 90vh;
        text-align: center;
        animation: slideIn 0.3s ease;
    }

    .rowfff {
        /* border: 2px solid blue; */
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        width: 80vw;
        margin: 0%;
    }
    
    #newfff{
        flex-direction: row !important;
        justify-content: space-evenly;
        margin: 2%;
    }

    .booking-form-card input,
    .booking-form-card textarea {
        width: 95%;
        padding: 10px;
        margin: 2% 2%;
        border-radius: 5px;
        border: 1px solid #ccc;
    }

    #tt {
        width: 95% !important;
    }

    .submit-booking-btn {
        background-color: #007bff;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 20vw !important;
        /* margin-top: 10px; */
    }
    
    .close-form-btn {
        /* margin-top: 15px; */
        padding: 10px;
        background-color: #ff4d4d;
        color: white;
        border: none;
        width: 20vw !important;
        border-radius: 5px;
        cursor: pointer;
    }
}