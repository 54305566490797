/* Container Styling */
.thankyou-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
}

/* Message Box Styling */
.thankyou-message {
    background: rgba(255, 255, 255, 0.95);
    border: 2px solid #8b4513;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 50px 30px;
    animation: fadeInZoom 1s ease-in-out;
    max-width: 600px;
}

/* Title Styling with Animation */
.animated-title {
    font-family: 'Georgia', serif;
    font-size: 42px;
    color: #8b4513;
    margin-bottom: 20px;
    animation: slideDown 1s ease-in-out;
}

.animated-description {
    font-size: 18px;
    color: #5a3e36;
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out 0.5s;
}

/* Button Styling */
.btn-home {
    display: inline-block;
    background-color: #8b4513;
    color: white;
    padding: 12px 24px;
    text-decoration: none;
    border-radius: 6px;
    font-size: 18px;
    transition: background-color 0.3s, transform 0.2s;
    animation: pulse 1.5s infinite;
}

.btn-home:hover {
    background-color: #a0522d;
    transform: scale(1.05);
}

/* Link Highlight Styling */
.highlight-link {
    color: #8b4513; /* Theme color */
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease-in-out;
}

.highlight-link:hover {
    color: #a0522d; /* Slightly lighter theme color */
}

/* Animations */
@keyframes fadeInZoom {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .thankyou-message {
        padding: 40px 20px;
    }

    .animated-title {
        font-size: 32px;
    }

    .animated-description {
        font-size: 16px;
    }

    .btn-home {
        font-size: 16px;
        padding: 10px 20px;
    }
}

@media (max-width: 480px) {
    .thankyou-message {
        padding: 30px 15px;
    }

    .animated-title {
        font-size: 28px;
    }

    .animated-description {
        font-size: 14px;
    }

    .btn-home {
        font-size: 14px;
        padding: 8px 16px;
    }
}
