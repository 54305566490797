#frame4 {
    height: 100vh;
    /* border: 2px solid yellow; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

#total {
    position: relative;
    z-index: 1;
}

#total::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(/src/Components/Images/total.JPG);
    background-size: 15vw 35vh;
    background-repeat: no-repeat;
    filter: blur(1px); /* Apply blur to background */
    z-index: -1; /* Make sure the pseudo-element is behind the content */
}

#city2 {
    position: relative;
    z-index: 1;
}

#city2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(/src/Components/Images/city2.JPG);
    background-size: 15vw 35vh;
    background-repeat: no-repeat;
    filter: blur(1px); /* Apply blur to background */
    z-index: -1; /* Make sure the pseudo-element is behind the content */
}


#f4b1 {
    /* border: 2px solid red; */
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#f4b1b1 {
    /* border: 2px solid green; */
    width: 15vw;
}

#f4b1b2 {
    /* border: 2px solid green; */
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.f4b1b2cards {
    /* border: 2px solid blue; */
    height: 35vh;
    width: 15vw;
    padding: 2%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    color: white;
    box-shadow: 5px 5px 5px 5px gray;
    z-index: 5;
}

.cardhead1 {
    font-size: 15px;
    font-family: "Lato", sans-serif !important;

}

.cardhead2 {
    font-size: 30px;
    font-family: "Noto Serif JP", serif !important;
}

#f4b1b2cardparts {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    border-radius: 10px;
    box-shadow: none;
    padding: 0%;
}

.f4b1b2cardparts {
    padding: 2%;
    width: 15vw;
    height: 15vh;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px gray;
}

#f4b2 {
    /* border: 2px solid red; */
    display: flex;
    width: 75vw;
    align-items: center;
    justify-content: space-around;
}

#f4b2b1 {
    /* border: 2px solid yellow; */
    width: 50vw;
}

#f4b2b2 {
    /* border: 2px solid yellow; */
    width: 40vw;
}

#f4b3 {
    /* border: 2px solid red; */
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: visible;
}

#forcards {
    /* border: 2px solid red; */
    width: 80vw;
    position: relative;
    /* opacity: 0; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 5%;
}


.f4b3cards {
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    width: 15vw;
    height: 35vh;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    top: -90px;
    z-index: 1;
    /* left: 20%; */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition for opacity */
}

.f4b3cards.show {
    opacity: 1;
    transform: translateY(-20px);
    z-index: 2;
}


.f4b3cardheads {
    font-size: 17px;
    font-family: "Noto Serif JP", serif !important;
    text-align: center;
}

.f4b3carddesc {
    display: flex;
    height: 16vh;
    /* align-items: center; */
    /* border:2px solid blue; */
    /* justify-content: center; */
    text-align: center;
    font-size: 14.5px;
}

#forblocks {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80vw;
}

.circles {
    border-radius: 100%;
    height: 5vh;
    width: 2.5vw;
    background-color: white;
    box-shadow: 5px 5px 5px 5px gray;
    position: relative;
    cursor: pointer;
    z-index: 1;
    /* Lower z-index so cards can appear above circles */
}

@media only screen and (max-width: 1203px) {
    #frame4 {
        height: 100vh;
        /* border: 2px solid yellow; */
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    #forcards {
        /* border: 2px solid red; */
        width: 80vw;
        position: relative;
        /* opacity: 0; */
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 10%;
    }
    
.f4b3cards {
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    width: 18vw;
    height: 35vh;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    top: -90px;
    z-index: 1;
    /* left: 20%; */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition for opacity */
}

.f4b3cards.show {
    opacity: 1;
    transform: translateY(-20px);
    z-index: 2;
}


.f4b3cardheads {
    font-size: 17px;
    text-align: center;
}

.f4b3carddesc {
    display: flex;
    height: 16vh;
    /* align-items: center; */
    /* border:2px solid blue; */
    /* justify-content: center; */
    text-align: center;
    font-size: 14.5px;
}
.circles {
    border-radius: 100%;
    height: 5vh;
    width: 3vw;
    background-color: white;
    box-shadow: 5px 5px 5px 5px gray;
    position: relative;
    cursor: pointer;
    z-index: 1;
    /* Lower z-index so cards can appear above circles */
}
}

@media only screen and (max-width: 956px) {
    #frame4 {
        height: 100vh;
        /* border: 2px solid rgb(0, 60, 255); */
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    #forcards {
        /* border: 2px solid red; */
        width: 80vw;
        position: relative;
        /* opacity: 0; */
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 10%;
    }
    
.f4b3cards {
    /* border: 2px solid red; */
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    width: 22vw;
    height: 35vh;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    top: -90px;
    z-index: 1;
    /* left: 20%; */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition for opacity */
}

.f4b3cards.show {
    opacity: 1;
    transform: translateY(5px);
    z-index: 2;
}


.f4b3cardheads {
    font-size: 17px;
    text-align: center;
}

.f4b3carddesc {
    display: flex;
    height: 16vh;
    /* align-items: center; */
    /* border:2px solid blue; */
    /* justify-content: center; */
    text-align: center;
    font-size: 14.5px;
}
.circles {
    border-radius: 100%;
    height: 5vh;
    width: 3.8vw;
    background-color: white;
    box-shadow: 5px 5px 5px 5px gray;
    position: relative;
    cursor: pointer;
    z-index: 1;
    /* Lower z-index so cards can appear above circles */
}
#f4b1 {
    /* border: 2px solid red; */
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#f4b1b1 {
    /* border: 2px solid green; */
    width: 20vw;
}

#f4b1b2 {
    /* border: 2px solid green; */
    width: 75vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.f4b1b2cards {
    /* border: 2px solid blue; */
    height: 45vh;
    width: 20vw;
    padding: 2%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    color: white;
    box-shadow: 5px 5px 5px 5px gray;
    z-index: 5;
}
#total::before {
    background-size: 20vw 45vh;
}

#city2::before {
    background-size: 20vw 45vh;
}
.f4b1b2cardparts {
    padding: 3%;
    width: 18vw;
    height: 18vh;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px gray;
}
}

@media only screen and (max-width: 835px) {
    #frame4 {
        height: 100vh;
        /* border: 2px solid rgb(255, 0, 0); */
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    #forcards {
        /* border: 2px solid red; */
        width: 75vw;
        position: relative;
        /* opacity: 0; */
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 10%;
    }
    
.f4b3cards {
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    width: 25vw;
    height: 35vh;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    top: -90px;
    z-index: 1;
    /* left: 20%; */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition for opacity */
}

.f4b3cards.show {
    opacity: 1;
    transform: translateY(5px);
    z-index: 2;
}


.f4b3cardheads {
    font-size: 17px;
    text-align: center;
}

.f4b3carddesc {
    display: flex;
    height: 20vh;
    /* align-items: center; */
    /* border:2px solid blue; */
    /* justify-content: center; */
    text-align: center;
    font-size: 14.5px;
}
.circles {
    border-radius: 100%;
    height: 5vh;
    width: 4.3vw;
    background-color: white;
    box-shadow: 5px 5px 5px 5px gray;
    position: relative;
    cursor: pointer;
    z-index: 1;
    /* Lower z-index so cards can appear above circles */
}
#f4b1 {
    /* border: 2px solid red; */
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#f4b1b1 {
    /* border: 2px solid green; */
    width: 20vw;
}

#f4b1b2 {
    /* border: 2px solid green; */
    width: 75vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.f4b1b2cards {
    /* border: 2px solid blue; */
    height: 45vh;
    width: 20vw;
    padding: 2%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    color: white;
    box-shadow: 5px 5px 5px 5px gray;
    z-index: 5;
}
#total::before {
    background-size: 20vw 45vh;
}

#city2::before {
    background-size: 20vw 45vh;
}
.f4b1b2cardparts {
    padding: 3%;
    width: 18vw;
    height: 18vh;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px gray;
}

.cardhead1 {
    font-size: 14px;
}

.cardhead2 {
    font-size: 25px;
}
}

@media only screen and (max-width: 740px) {
    #frame4 {
        height: 175vh;
        /* border: 2px solid rgb(14, 101, 34); */
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    #forcards {
        /* border: 2px solid red; */
        width: 80vw;
        position: relative;
        /* opacity: 0; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 10%;
    }
    
.f4b3cards {
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    width: 35vw;
    height: 25vh;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    top: -90px;
    z-index: 1;
    /* left: 20%; */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition for opacity */
}

.f4b3cards.show {
    opacity: 1;
    transform: translateY(5px);
    z-index: 2;
}


.f4b3cardheads {
    font-size: 17px;
    text-align: center;
}

.f4b3carddesc {
    display: flex;
    height: 25vh;
    /* align-items: center; */
    /* border:2px solid blue; */
    /* justify-content: center; */
    text-align: center;
    font-size: 14.5px;
}
.circles {
    border-radius: 100%;
    height: 5vh;
    width: 4.8vw;
    background-color: white;
    box-shadow: 5px 5px 5px 5px gray;
    position: relative;
    cursor: pointer;
    z-index: 1;
    /* Lower z-index so cards can appear above circles */
}
#f4b1 {
    /* border: 2px solid red; */
    width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#f4b1b1 {
    /* border: 2px solid green; */
    width: 70vw;
}

#f4b1b2 {
    /* border: 2px solid green; */
    width: 85vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.f4b1b2cards {
    /* border: 2px solid blue; */
    height: 30vh;
    width: 25vw;
    padding: 2%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    color: white;
    box-shadow: 5px 5px 5px 5px gray;
    z-index: 5;
}
#total::before {
    background-size: 25vw 35vh;
}

#city2::before {
    background-size: 25vw 35vh;
}
.f4b1b2cardparts {
    padding: 3%;
    width: 25vw;
    height: 12vh;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px gray;
}

.cardhead1 {
    font-size: 12px;
}

.cardhead2 {
    font-size: 18px;
}
#forblocks{
    flex-direction: column;
    /* border: 2px solid blue; */
    height: 70vh;
    width: 100%;
}
#f4b3 {
    /* border: 2px solid red; */
    width: 80vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
}

@media only screen and (max-width: 680px) {
    #frame4 {
        height: 175 vh;
        /* border: 2px solid rgb(255, 242, 0); */
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        /* padding: 10%; */
    }


    #forcards {
        /* border: 2px solid red; */
        width: 80vw;
        position: relative;
        /* opacity: 0; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 10%;
    }
    
.f4b3cards {
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    width: 40vw;
    height: 25vh;
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    top: -90px;
    z-index: 1;
    /* left: 20%; */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition for opacity */
}

.f4b3cards.show {
    opacity: 1;
    transform: translateY(5px);
    z-index: 2;
}


.f4b3cardheads {
    font-size: 17px;
    text-align: center;
}

.f4b3carddesc {
    display: flex;
    height: 25vh;
    /* align-items: center; */
    /* border:2px solid blue; */
    /* justify-content: center; */
    text-align: center;
    font-size: 14.5px;
}
.circles {
    border-radius: 100%;
    height: 5vh;
    width: 5vw;
    background-color: white;
    box-shadow: 5px 5px 5px 5px gray;
    position: relative;
    cursor: pointer;
    z-index: 1;
    /* Lower z-index so cards can appear above circles */
}
#f4b1 {
    /* border: 2px solid red; */
    width: 85vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#f4b1b1 {
    /* border: 2px solid green; */
    width: 70vw;
}

#f4b1b2 {
    /* border: 2px solid green; */
    width: 85vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.f4b1b2cards {
    /* border: 2px solid blue; */
    height: 30vh;
    width: 25vw;
    padding: 2%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    color: white;
    box-shadow: 5px 5px 5px 5px gray;
    z-index: 5;
}
#total::before {
    background-size: 25vw 35vh;
}

#city2::before {
    background-size: 25vw 35vh;
}
.f4b1b2cardparts {
    padding: 3%;
    width: 25vw;
    height: 12vh;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px gray;
}

.cardhead1 {
    font-size: 12px;
}

.cardhead2 {
    font-size: 18px;
}
#forblocks{
    flex-direction: column;
    /* border: 2px solid blue; */
    height: 70vh;
}
#f4b3 {
    /* border: 2px solid red; */
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
#f4b2 {
    /* border: 2px solid red; */
    display: flex;
    width: 85vw;
    align-items: center;
    justify-content: space-around;
}

#f4b2b1 {
    /* border: 2px solid yellow; */
    width: 50vw;
}

#f4b2b2 {
    /* border: 2px solid yellow; */
    width: 40vw;
}
}

@media only screen and (max-width: 412px) {
    #frame4 {
        height: 175vh;
        /* border: 2px solid rgb(255, 242, 0); */
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
    }

    #forcards {
        /* border: 2px solid red; */
        width: 80vw;
        position: relative;
        /* opacity: 0; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: -20%;
        /* transform: translate(0vw,-20vh); */
    }
    
.f4b3cards {
    box-shadow: 5px 5px 5px 5px gray;
    background-color: white;
    width: 60vw;
    height: 20vh !important;
    /* border: 2px solid red; */
    padding: 2%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    opacity: 0;
    position: absolute;
    top: -15vh;
    z-index: 1;
    /* margin-bottom: 20vh; */
    /* left: 20%; */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition for opacity */
}

.f4b3cards.show {
    opacity: 1;
    transform: translateY(5px);
    z-index: 2;
}


.f4b3cardheads {
    font-size: 17px;
    text-align: center;
}

.f4b3carddesc {
    display: flex;
    height: 12vh;
    /* align-items: center; */
    /* border:2px solid blue; */
    /* justify-content: center; */
    text-align: center;
    font-size: 14.5px;
}
.circles {
    border-radius: 100%;
    height: 5vh;
    width: 10vw;
    background-color: white;
    box-shadow: 5px 5px 5px 5px gray;
    position: relative;
    cursor: pointer;
    z-index: 1;
    /* margin-bottom: 5%; */
    /* Lower z-index so cards can appear above circles */
}
#f4b1 {
    /* border: 2px solid red; */
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

#f4b1b1 {
    /* border: 2px solid green; */
    width: 90vw;
    padding: 2%;
}

#f4b1b2 {
    /* border: 2px solid green; */
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.f4b1b2cards {
    /* border: 2px solid blue; */
    height: 25vh;
    width: 25vw;
    padding: 2%;
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    color: white;
    box-shadow: 5px 5px 5px 5px gray;
    z-index: 5;
}
#total::before {
    background-size: 25vw 35vh;
}

#city2::before {
    background-size: 25vw 35vh;
}
.f4b1b2cardparts {
    padding: 3%;
    width: 25vw;
    height: 10vh;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 5px gray;
}

.cardhead1 {
    font-size: 12px;
}

.cardhead2 {
    font-size: 18px;
}
#forblocks{
    flex-direction: column;
    /* border: 2px solid blue; */
    height: 90vh;
}
#f4b3 {
    /* border: 2px solid rgb(157, 0, 255); */
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
#f4b2 {
    /* border: 2px solid red; */
    display: flex;
    width: 90vw;
    align-items: center;
    justify-content: space-around;
}

#f4b2b1 {
    /* border: 2px solid yellow; */
    width: 50vw;
}

#f4b2b2 {
    /* border: 2px solid yellow; */
    width: 65vw;
}
}