/* Style for the floating WhatsApp button */
.whatsapp_float {
    /* border: 2px solid red; */
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 60px; /* Adjust position from the bottom */
    right: 30px; /* Adjust position from the right */
    z-index: 1000; /* Make sure it stays on top */
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    background: transparent;
}

.whatsapp_float:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
}

.whatsapp_icon {
    width: 120%;
    height: 120%;
    border-radius: 50%;
    background: transparent;
}
